:root {
  --main-color: #000;
  --main-color-rgb: 0, 0, 0;
  --secondary-color: #fff;
  --highlight-color: #e6007d;
  --default-background-color: #fff;
  --primary-background-color: #002a5c;
  --secondary-background-color: #93c5ff;
  --tertiary-background-color: #e0eeff;
  --tertiary-background-color-rgb: 224, 238, 255;
  --checkbox-color: #fff;
  --checkbox-higlight-color: #90b69f;
  --toggle-color: #ccc;
  --toggle-enabled-color: #e6007d;
  --button-color: #ccc;
  --button-highlight-color: #e6007d;
  --mobile-breakpoint: 410px;
  --tablet-breaktpoint: 580px;
  --desktop-breakpoint: 875px;
  --food-drink-color: #84bd2f;
  --sleep-color: #258cc4;
  --hygiene-color: #087e87;
  --med-color: #d33e41;
  --consulting-color: #ef9c16;
  --misc-color: #7a5ca5;
  --desktop-list-width: 380px;
  --button-shadow: 0px 1px 1px #00000040;
  --box-shadow: 0 2px 4px rgba(var(--main-color-rgb), .14), 0 3px 4px rgba(var(--main-color-rgb), .12), 0 1px 5px rgba(var(--main-color-rgb), .2);
  --box-shadow-inset: 0 -2px 1px #00000040;
  --info-color: #ef9c16, 15;
  --success-color: #84bd2f;
  --error-color: #c80e11;
  --icon-width: 1.5rem;
  --icon-height: 1.5rem;
}

.root {
  width: 100%;
  height: 100%;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
}

html {
  width: 100%;
  height: 100%;
}

html, body {
  width: 100%;
  height: 100%;
  background-color: var(--tertiary-background-color);
  font-family: Fira Sans, sans-serif;
  font-weight: 400;
  line-height: 1.4;
}

a {
  color: var(--main-color);
  text-decoration: none;
}

p {
  color: var(--main-color);
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

article {
  width: 100%;
  max-width: 42rem;
  background-color: var(--tertiary-background-color);
  margin: 0 auto;
  padding: 1.5rem;
}

article ul, article li {
  color: var(--main-color);
  margin: 0;
  padding: 0;
  list-style: none;
}

article li {
  align-items: center;
  display: flex;
}

article p {
  color: var(--main-color);
  padding: .625rem 0;
}

article img {
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: .625rem 0;
  display: block;
}

article img[src*="icons"] {
  margin: 0 .625rem 0 0;
  display: inline-block;
}

html[dir="rtl"] article img[src*="icons"] {
  margin-left: .625rem;
  margin-right: 0;
}

h2 {
  color: var(--main-color);
  margin: 1.875rem 0 1rem;
}

.hidden {
  display: none;
}

dl {
  font-size: .875rem;
}

dl > div {
  break-inside: avoid;
}

dl dd, dl dt {
  width: 50%;
  vertical-align: top;
  margin: 0 0 .5rem;
  display: inline-block;
}

dl dt {
  color: var(--gray-dark-2);
}

dl dt:after {
  content: ":";
}

.checkbox-list {
  width: 100%;
  flex-wrap: wrap;
  padding: 0 1.25rem;
  font-size: 1rem;
  display: flex;
}

.checkbox-list--item {
  width: 50%;
}

input[type="checkbox"], input[type="radio"] {
  display: none;
}

input[type="checkbox"] + label, input[type="radio"] + label {
  cursor: pointer;
  background-color: var(--default-background-color);
  fill: currentColor;
}

input[type="checkbox"]:checked + label, input[type="radio"]:checked + label {
  fill: var(--secondary-color);
  background-color: currentColor;
}

.flyout {
  box-sizing: content-box;
  width: 15rem;
  background-color: var(--secondary-background-color);
  box-shadow: var(--box-shadow);
  border-bottom-left-radius: .25rem;
  padding: 1rem;
  transition: all .25s ease-in-out;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateX(100%);
}

.flyout header {
  margin-bottom: 1rem;
  font-weight: 700;
}

.flyout a {
  text-decoration: underline;
}

.flyout .cta {
  box-sizing: content-box;
  width: 3.43rem;
  height: 2.375rem;
  background-color: var(--secondary-background-color);
  cursor: pointer;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  padding: 1rem .5rem;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.flyout .cta svg {
  height: 100%;
}

.flyout-open {
  transform: translateX(0);
}

.icon.arrow {
  padding: .25rem;
  display: none;
}

.flyout-open .icon.arrow, .icon.logo {
  display: block;
}

.flyout-open .icon.logo {
  display: none;
}

.debug {
  background: var(--error-color);
  color: var(--secondary-color);
  z-index: 1000;
  border-radius: 3px;
  padding: 3px 5px;
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
}

.CTLFwq_overlay:before {
  content: "";
  opacity: .7;
  background-color: currentColor;
  position: absolute;
  inset: 0;
}

.CTLFwq_overlay * {
  position: relative;
}

.CTLFwq_header, .CTLFwq_headerFullScreen {
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  font-size: 1rem;
}

.CTLFwq_headerFullScreen {
  height: 100%;
  flex-direction: column;
  display: flex;
  position: fixed;
  overflow: auto;
}

.CTLFwq_headerMenu, .CTLFwq_headerMenuFullScreen {
  position: relative;
}

.CTLFwq_headerMenuFullScreen {
  background: var(--primaryBackgroundColor);
  z-index: 1;
  width: 100%;
  position: fixed;
}

.CTLFwq_backArrow {
  width: var(--icon-width);
  height: var(--icon-height);
  fill: var(--main-color);
  position: absolute;
  top: .75rem;
  left: .625rem;
}

.CTLFwq_logo, .CTLFwq_logoBig {
  width: 2.8125rem;
  margin: auto;
  padding: .375rem 0;
  display: block;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .CTLFwq_logo, .CTLFwq_logoBig {
    height: 2.8rem;
  }
}

.CTLFwq_logoBig {
  width: 6.25rem;
  padding: 1rem 0 .625rem;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .CTLFwq_logoBig {
    height: 4rem;
  }
}

@media (min-width: 580px) {
  .CTLFwq_logoBig {
    width: 8.31rem;
  }
}

.CTLFwq_menuToggle {
  width: var(--icon-width);
  height: var(--icon-height);
  fill: var(--main-color);
  position: absolute;
  top: .75rem;
  right: .625rem;
}

.CTLFwq_introText {
  text-align: center;
  color: var(--secondary-color);
  margin: 0 auto;
  padding: .75rem 2rem 2rem;
}

@media (min-width: 875px) {
  .CTLFwq_introText {
    font-size: 1.2rem;
  }
}

.Py25IW_navigation {
  background-color: var(--secondary-background-color);
  text-align: center;
  color: var(--main-color);
  flex-grow: 1;
  margin: 0;
  padding-top: 5.58333em;
  padding-bottom: .833333em;
  padding-left: 0;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.6;
  list-style-type: none;
  position: relative;
}

.Py25IW_navigationItem a {
  padding: .416667em 0;
  display: inline-block;
}

.Py25IW_hidden {
  display: none;
}

.MCZWZa_container {
  max-width: 500px;
  margin: 0 auto;
  font-size: 1.5rem;
  position: relative;
}

.MCZWZa_selectContainer {
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.MCZWZa_selectContainerCollapsed {
  display: none;
}

.MCZWZa_header {
  background-color: var(--secondary-background-color);
  cursor: pointer;
  padding: .4rem;
}

.MCZWZa_headerActivated {
  background-color: var(--primary-background-color);
  color: var(--secondary-color);
}

.MCZWZa_option {
  background-color: var(--tertiary-background-color);
  cursor: pointer;
  padding: .4rem;
}

.MCZWZa_optionActive {
  background-color: rgba(var(--tertiary-background-color-rgb), .2);
  color: var(--secondary-color);
}

.qORVMW_container {
  color: currentColor;
  width: 3em;
  height: 3em;
  display: inline-block;
}

.qORVMW_icon {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.-qPWiG_container {
  justify-content: center;
  display: flex;
}

.-qPWiG_containerOffline {
  pointer-events: none;
}

.-qPWiG_containerOffline .-qPWiG_shareIcon {
  color: var(--main-color);
  filter: brightness(70);
}

.-qPWiG_shareIcon {
  color: var(--main-color);
  width: 2.25em;
  height: 2.25em;
  margin: .9375em;
}

@media (min-width: 580px) {
  .-qPWiG_shareIcon {
    margin: 1.5em;
  }
}

.-qPWiG_hidden {
  display: none;
}

.usRRJW_wrapper {
  height: 100%;
  width: 100%;
  color: var(--primary-background-color);
  flex-direction: column;
  display: flex;
  overflow: auto;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .usRRJW_wrapper > * {
    flex-shrink: 0;
  }
}

.usRRJW_wrapperMap {
  overflow: hidden;
}

@media (min-width: 580px) {
  .usRRJW_wrapperMap {
    overflow: visible;
  }
}

.usRRJW_wrapper--foodDrink {
  color: var(--food-drink-color);
}

.usRRJW_wrapper--sleep {
  color: var(--sleep-color);
}

.usRRJW_wrapper--hygiene {
  color: var(--hygiene-color);
}

.usRRJW_wrapper--medicalAid {
  color: var(--med-color);
}

.usRRJW_wrapper--informationAdvice {
  color: var(--consulting-color);
}

.usRRJW_wrapper--other {
  color: var(--misc-color);
}

.usRRJW_offline {
  padding-bottom: 2.75rem;
}

.usRRJW_section, .usRRJW_filterSection, .usRRJW_categorySection, .usRRJW_mapSection {
  width: 100%;
  position: relative;
}

.usRRJW_filterSection {
  min-height: 3.75rem;
  background: var(--secondary-background-color);
}

@media (min-width: 875px) {
  .usRRJW_filterSection {
    min-height: 0;
    height: auto;
  }
}

.usRRJW_categorySection {
  background: var(--tertiary-background-color);
  flex-grow: 1;
}

.usRRJW_mapSection {
  flex: 0 100%;
  display: flex;
}

.usRRJW_hidden {
  display: none;
}

._5BPO0G_container {
  flex-direction: column;
  flex-grow: 1;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (min-width: 875px) {
  ._5BPO0G_container {
    height: 100%;
  }
}

._5BPO0G_containerFullscreen {
  z-index: 1;
  background: #fff;
  position: absolute;
  inset: 3.4375em 0 0;
}

._5BPO0G_header {
  box-shadow: var(--box-shadow);
  z-index: 1;
  flex-shrink: 0;
  align-items: center;
  padding: .6875em 3.125em .5em 1em;
  display: flex;
  position: relative;
}

._5BPO0G_header:before {
  opacity: .3;
}

._5BPO0G_list {
  background: #fff;
  margin: 0;
  padding: 0 0 0 .9375em;
  list-style: none;
  overflow-y: auto;
}

@media (max-width: 875px) {
  ._5BPO0G_listSmall {
    max-height: 300px;
    z-index: 1;
    position: absolute;
    top: 3.4375em;
    left: 44px;
    right: 44px;
  }
}

@media (min-width: 875px) {
  ._5BPO0G_list {
    display: block;
  }
}

._5BPO0G_listIcon, ._5BPO0G_mapIcon {
  height: 1.125em;
  width: 1.125em;
  flex-shrink: 0;
  margin-right: .75em;
}

@media (min-width: 875px) {
  ._5BPO0G_listIcon, ._5BPO0G_mapIcon {
    display: none;
  }
}

._5BPO0G_filterIcon {
  height: 1.125em;
  width: 1.125em;
  margin-right: .75em;
}

._5BPO0G_listItem {
  border-top: 1px solid var(--main-color);
  min-height: 4.0625em;
  color: var(--main-color);
  cursor: pointer;
  padding: .5em 0 .5em .5em;
}

._5BPO0G_listItemAddress {
  color: gray;
}

._5BPO0G_listItemActive ._5BPO0G_listItemAddress {
  color: var(--main-color);
}

._5BPO0G_input {
  box-sizing: border-box;
  width: 100%;
  border: none;
  border-radius: 2px;
  padding: .625em 1.875em .625em .625em;
  transition: border .2s, box-shadow .2s;
}

._5BPO0G_input:focus {
  box-shadow: var(--box-shadow-inset);
  outline: none;
}

._5BPO0G_listImage {
  max-width: 100%;
  border-top: 1px solid var(--main-color);
  box-sizing: border-box;
  object-fit: cover;
  padding: .625em .625em .625em 0;
  display: block;
}

.WMQgRG_container {
  width: 100%;
  box-shadow: var(--box-shadow);
  z-index: 1;
  box-sizing: border-box;
  background-color: #fff;
  padding: .6875em .625em .6875em .9375em;
  position: relative;
}

@media (min-width: 875px) {
  .WMQgRG_container {
    width: 21.875em;
    min-height: 0;
    box-shadow: var(--box-shadow);
    background-color: #fff;
    padding: 0;
    position: absolute;
    top: 4.375em;
    left: .9375em;
  }
}

.WMQgRG_container:before {
  opacity: .3;
  opacity: .3;
}

.WMQgRG_header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.WMQgRG_filterSections {
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
  box-shadow: var(--box-shadow);
  background-color: #fff;
  padding: 0 .9375em .6875em;
  position: absolute;
  left: 0;
}

.WMQgRG_filterSections:before {
  opacity: .3;
}

@media (min-width: 875px) {
  .WMQgRG_filterSections {
    padding: .9375em;
  }
}

.WMQgRG_filterSection {
  border: none;
  flex-grow: 1;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  padding: .5em 0;
  display: flex;
  position: relative;
}

.WMQgRG_sectionTitle {
  flex: 0 0 6.25em;
  display: inline-block;
}

.WMQgRG_sectionContent {
  flex: 1 0;
  display: flex;
}

.WMQgRG_personalFilterContainer {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.WMQgRG_categoriesContainer {
  background: #e8f8ee;
  flex-grow: 1;
  padding: .9375em;
}

.WMQgRG_categoriesHeader {
  margin-bottom: .625em;
  position: relative;
}

.WMQgRG_resetCategory {
  position: absolute;
  top: -3px;
  right: 0;
}

.WMQgRG_categoriesContent {
  flex-wrap: wrap;
  display: flex;
}

.WMQgRG_category, .WMQgRG_subCategory {
  text-overflow: ellipsis;
  flex-direction: column;
  flex: 0 0 calc(33% - .875em);
  align-items: center;
  margin: .4375em .4375em 1.25em;
  display: flex;
  overflow: hidden;
}

.WMQgRG_category .WMQgRG_categoryIcon, .WMQgRG_subCategory .WMQgRG_categoryIcon {
  width: 3.75em;
  max-width: 100%;
  margin-bottom: .625em;
}

.WMQgRG_subCategory {
  flex: 0 0 calc(25% - .875em);
  margin: .4375em;
}

.WMQgRG_hidden {
  display: none;
}

.WMQgRG_label, .WMQgRG_labelPersonalFilter {
  width: 2.1875em;
  height: 2.1875em;
  justify-content: center;
  align-items: center;
  margin-right: .9375em;
  display: flex;
}

.WMQgRG_labelPersonalFilter {
  border-radius: 50%;
}

.aYpx5G_container {
  background: #fff;
}

@media (min-width: 875px) {
  .aYpx5G_container {
    width: 23.75em;
    z-index: 1;
    position: absolute;
    top: 3.4375em;
    bottom: 0;
  }
}

.aYpx5G_icon {
  cursor: pointer;
  fill: var(--main-color);
  z-index: 1;
  position: absolute;
  top: .8125em;
  right: .625em;
}

html[dir="rtl"] .aYpx5G_icon {
  margin-left: 0;
  margin-right: .625em;
}

.aYpx5G_compare {
  min-height: 1.57143em;
  min-height: 3.71429em;
  justify-content: space-between;
  padding: 1.07143em;
  font-size: .875rem;
  display: none;
}

@media (min-width: 875px) {
  .aYpx5G_compare {
    display: flex;
  }
}

.aYpx5G_compareButton {
  text-align: center;
  color: currentColor;
  cursor: pointer;
  min-height: 1.42857em;
  background-color: #fff;
  border: 1px solid;
  outline: none;
  flex-grow: 1;
}

.aYpx5G_active {
  background-color: currentColor;
}

.aYpx5G_active span {
  color: #fff;
}

.aYpx5G_compareButton:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.aYpx5G_compareButton:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.sn_wdG_toggleSwitch {
  width: 2.59375em;
  height: 1.3125em;
  flex-shrink: 0;
  align-self: center;
  display: inline-block;
  position: relative;
}

.sn_wdG_toggleSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sn_wdG_slider {
  cursor: pointer;
  background-color: #ccc;
  margin: 0;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.sn_wdG_slider:before {
  content: "";
  height: .9375em;
  width: .9375em;
  background-color: #fff;
  transition: all .4s;
  position: absolute;
  bottom: .1875em;
  left: .1875em;
}

input:checked + .sn_wdG_slider {
  background-color: #e6007d;
}

input:focus + .sn_wdG_slider {
  box-shadow: 0 0 2px #e6007d24, 0 2px 2px #e6007d1f, 0 1px 3px #e6007d33;
}

input:checked + .sn_wdG_slider:before {
  transform: translateX(1.25em);
}

.sn_wdG_slider.sn_wdG_round {
  border-radius: 2.125em;
}

.sn_wdG_slider.sn_wdG_round:before {
  border-radius: 50%;
}

._7D2zJq_container {
  height: 2.375em;
  min-width: 6.25em;
  color: currentColor;
  box-shadow: var(--box-shadow);
  cursor: pointer;
  background-color: currentColor;
  border: none;
  border-radius: 4px;
  align-self: center;
  align-items: center;
  padding: .5em 1.5em;
  font-size: 1rem;
  display: flex;
  position: relative;
}

._7D2zJq_container._7D2zJq_hasIcon {
  padding-right: 5em;
  position: relative;
}

._7D2zJq_container:disabled ._7D2zJq_text {
  color: #76ac27;
}

._7D2zJq_container:disabled ._7D2zJq_icon {
  fill: #dbe9c1;
}

._7D2zJq_container > *, ._7D2zJq_text {
  color: #fff;
}

._7D2zJq_iconContainer {
  width: 3.75em;
  background: #fff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.sxW9kG_disclaimerRoot {
  display: none;
}

.sxW9kG_disclaimerRootVisible {
  display: initial;
  z-index: 40;
}

.sxW9kG_container {
  width: 100%;
  z-index: 40;
  max-width: 31.25em;
  position: absolute;
  bottom: 0;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%);
}

.sxW9kG_content {
  box-shadow: var(--box-shadow);
  background-color: #fff;
  border-radius: .25em;
  margin: .625em;
  padding: .9375em;
  overflow: hidden;
}

.sxW9kG_settingsContainer {
  flex-direction: column;
  display: flex;
}

.sxW9kG_buttons {
  flex-direction: column;
  justify-content: end;
  align-self: flex-end;
  margin-top: .9375em;
  display: flex;
}

.sxW9kG_buttonAccept {
  color: #e6007d;
  align-self: flex-end;
}

.sxW9kG_buttonSettings {
  width: 100%;
  color: #ccc;
  align-self: flex-end;
  margin-bottom: .9375em;
}

.sxW9kG_settingsIcon {
  fill: #ccc;
}

.sxW9kG_settingsSwitch {
  justify-content: space-between;
  margin-bottom: .9375em;
  padding: .3125em .625em;
  display: flex;
}

.M1X-rG_article a {
  text-decoration: underline;
}

.SCY8Fa_categoriesContainer {
  width: 100%;
  color: var(--main-color);
  margin: 0 auto;
  padding: .9375em;
}

@media screen and (min-width: 580px) {
  .SCY8Fa_categoriesContainer {
    max-width: 40rem;
    width: 40rem;
  }
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .SCY8Fa_categoriesContainer {
    width: 100%;
  }
}

.SCY8Fa_categoriesHeader {
  margin-bottom: .625em;
  position: relative;
}

.SCY8Fa_removeCategory {
  position: absolute;
  top: -.1875em;
  right: 0;
}

html[dir="rtl"] .SCY8Fa_removeCategory {
  left: 0;
  right: auto;
}

.SCY8Fa_categoriesContent {
  flex-wrap: wrap;
  display: flex;
}

.SCY8Fa_subCategories {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.SCY8Fa_subCategories .SCY8Fa_categoriesContent {
  width: 100%;
}

.SCY8Fa_subCategories .SCY8Fa_category {
  flex-direction: column;
  flex: 50%;
  justify-content: center;
  align-items: center;
  padding-left: 0;
}

@media (min-width: 410px) {
  .SCY8Fa_subCategories .SCY8Fa_category {
    flex: 50%;
  }
}

@media (min-width: 580px) {
  .SCY8Fa_subCategories .SCY8Fa_category {
    flex: 50%;
  }
}

.SCY8Fa_subCategories .SCY8Fa_categoryIcon {
  width: 4em;
  margin-bottom: 1.25em;
}

.SCY8Fa_subCategories .SCY8Fa_parentCategory .SCY8Fa_categoryIcon {
  width: 5rem;
  margin-bottom: 1.25em;
}

@media (min-width: 580px) {
  .SCY8Fa_subCategories .SCY8Fa_parentCategory .SCY8Fa_categoryIcon {
    width: 7rem;
  }
}

@media (min-width: 875px) {
  .SCY8Fa_subCategories .SCY8Fa_categoryIcon {
    width: 5.3125em;
  }
}

.SCY8Fa_category {
  text-align: center;
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 0;
  flex-basis: 50%;
  align-items: center;
  margin-bottom: 1.25em;
  display: flex;
  overflow: hidden;
}

.SCY8Fa_categoryIcon {
  width: 4.0625em;
  margin-bottom: .625em;
}

@media (min-width: 580px) {
  .SCY8Fa_categoryIcon {
    width: 5.3125em;
  }
}

.SCY8Fa_parentCategory {
  height: 100%;
  flex: 0 33%;
  padding-right: .75em;
}

.SCY8Fa_hidden {
  display: none;
}

.NfOD_G_locationIconContainer {
  cursor: pointer;
  background-color: #fff;
  border-radius: .125em;
  padding: .25em;
  position: absolute;
  bottom: 5.9375em;
  right: .625em;
  transform: translateY(-50%);
  box-shadow: 0 1px 1px #00000040;
}

.NfOD_G_requestLocationIcon {
  fill: #666;
  display: block;
}

.NfOD_G_spinner {
  fill: none;
  stroke-width: .5em;
  width: 2rem;
  height: 2rem;
  animation: NfOD_G_rotator 1.5s linear infinite;
  display: block;
}

.NfOD_G_path {
  stroke-dasharray: 200;
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke: #666;
  animation: NfOD_G_dash 1.5s ease-in-out infinite;
}

@keyframes NfOD_G_dash {
  0% {
    stroke-dashoffset: 200px;
  }

  50% {
    stroke-dashoffset: 50px;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 200px;
    transform: rotate(450deg);
  }
}

@keyframes NfOD_G_rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

._1UXyLq_container {
  height: calc(100% - 114px);
  position: relative;
  overflow: hidden;
}

._1UXyLq_container *, ._1UXyLq_container :before, ._1UXyLq_container :after {
  box-sizing: initial;
}

@media (min-width: 875px) {
  ._1UXyLq_container {
    height: calc(100% - 55px);
    padding-left: 23.75em;
  }
}

._1UXyLq_containerInner {
  height: 100%;
  position: relative;
}

._1UXyLq_container--foodDrink {
  color: #84bd2f;
}

._1UXyLq_container--sleep {
  color: #258cc4;
}

._1UXyLq_container--hygiene {
  color: #087e87;
}

._1UXyLq_container--medicalAid {
  color: #d33e41;
}

._1UXyLq_container--informationAdvice {
  color: #ef9c16;
}

._1UXyLq_container--other {
  color: #7a5ca5;
}

._1UXyLq_mapCanvas {
  width: 100%;
  height: 100%;
}

._1UXyLq_messageContainer {
  height: 100%;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
}

._1UXyLq_messageContainer:before {
  opacity: .3;
}

._1UXyLq_notUsableMessage {
  color: var(--main-color);
  max-width: 300px;
  text-align: center;
}

._1UXyLq_hidden {
  display: none;
}

._5XHz1W_container {
  color: currentColor;
  cursor: pointer;
  background-color: currentColor;
  border-radius: 4px;
  align-self: center;
  padding: .5em 6em .5em 1.5em;
  position: relative;
  box-shadow: 0 1px 1px #00000040;
}

._5XHz1W_text {
  width: 100%;
  text-align: center;
  color: #fff;
  display: inline-block;
}

._5XHz1W_icon {
  height: 1.3125em;
  width: 1.25em;
  fill: currentColor;
  box-sizing: initial;
  background: #fff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: .5625em 1.625em;
  position: absolute;
  top: 0;
  right: 0;
}

.VUWpEG_container {
  height: auto;
  width: 100%;
  max-width: 100%;
  z-index: 1;
  box-shadow: var(--box-shadow);
  background-color: #fff;
  position: relative;
}

.VUWpEG_container *, .VUWpEG_container :before, .VUWpEG_container :after {
  box-sizing: initial;
}

.VUWpEG_container:before {
  opacity: .3;
}

@media (min-width: 875px) {
  .VUWpEG_container {
    width: 23.75em;
    background-color: #fff;
    flex-direction: column;
    display: flex;
    position: absolute;
    top: 3.4375em;
    bottom: 0;
    left: 0;
  }
}

.VUWpEG_header {
  box-shadow: var(--box-shadow-inset);
  background-color: #fff;
  flex-shrink: 0;
  position: relative;
}

@media (min-width: 875px) {
  .VUWpEG_header {
    box-shadow: none;
  }
}

.VUWpEG_title {
  box-sizing: border-box;
  background-position: 0 -55px;
  justify-content: space-between;
  align-items: center;
  padding: .9375em;
  display: flex;
  position: relative;
}

.VUWpEG_name {
  direction: ltr;
  color: var(--main-color);
  flex: 0 75%;
  font-weight: 900;
}

@media (min-width: 875px) {
  .VUWpEG_name {
    flex-grow: 1;
  }
}

html[dir="rtl"] .VUWpEG_name {
  flex-direction: row-reverse;
  display: flex;
}

.VUWpEG_toggle {
  transition: all .2s;
}

html[dir="rtl"] .VUWpEG_toggle {
  left: .9375em;
  right: auto;
}

.VUWpEG_toggle svg {
  transition: all .2s;
  transform: rotate(-180deg);
}

.VUWpEG_toggleOpen svg {
  transform: rotate(0);
}

@media (min-width: 875px) {
  .VUWpEG_toggle {
    display: none;
  }
}

.VUWpEG_close {
  transition: all .2s;
  display: none;
}

@media (min-width: 875px) {
  .VUWpEG_close {
    display: block;
  }
}

html[dir="rtl"] .VUWpEG_close {
  left: .9375em;
  right: auto;
}

.VUWpEG_close svg {
  transition: all .2s;
  transform: rotate(-180deg);
}

.VUWpEG_closeOpen svg {
  transform: rotate(0);
}

.VUWpEG_filter {
  min-height: 1.5625em;
  background-color: #fff;
  align-items: center;
  padding: .4375em .9375em;
  display: flex;
  position: relative;
}

.VUWpEG_distance {
  color: var(--main-color);
}

.VUWpEG_content {
  height: 100%;
  max-height: 15.625em;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: auto;
}

@media (min-width: 875px) {
  .VUWpEG_content {
    max-height: none;
    padding: 0 .9375em;
  }
}

.VUWpEG_contentItem {
  box-sizing: border-box;
  width: 100%;
  max-width: 31.25em;
  text-align: left;
  flex-shrink: 0;
  padding: .3125em .9375em;
  display: flex;
  position: relative;
}

.VUWpEG_contentItem:before {
  opacity: .3;
}

.VUWpEG_contentItem:first-child {
  padding-top: .9375em;
}

.VUWpEG_contentItem:last-child {
  padding-bottom: .9375em;
}

@media (min-width: 875px) {
  .VUWpEG_contentItem {
    padding: .5em 0;
  }
}

.VUWpEG_icon, .VUWpEG_iconDirections {
  fill: currentColor;
  flex-shrink: 0;
  margin-right: .625em;
}

html[dir="rtl"] .VUWpEG_icon, html[dir="rtl"] .VUWpEG_iconDirections {
  margin-left: .625em;
  margin-right: 0;
}

.VUWpEG_iconDirections {
  fill: var(--main-color);
  position: absolute;
  top: 1.25em;
  right: .3125em;
}

@media (min-width: 875px) {
  .VUWpEG_iconDirections {
    display: none;
  }
}

html[dir="rtl"] .VUWpEG_iconDirections {
  left: .3125em;
  right: auto;
}

.VUWpEG_iconShare {
  width: 1.25em;
  height: 1.25em;
  cursor: pointer;
  position: absolute;
  top: .625em;
  right: .9375em;
}

@media (min-width: 875px) {
  .VUWpEG_iconShare {
    display: none;
  }
}

.VUWpEG_button {
  flex-shrink: 0;
  margin-top: 1em;
}

@media (min-width: 875px) {
  .VUWpEG_button {
    width: 12.5em;
  }
}

.VUWpEG_button:last-of-type {
  margin-bottom: 1em;
}

.VUWpEG_button[disabled] {
  pointer-events: none;
  opacity: .3;
}

.VUWpEG_directionsButton {
  display: none;
}

@media (min-width: 875px) {
  .VUWpEG_directionsButton {
    display: block;
  }
}

.VUWpEG_share {
  width: 100%;
  background: #fff;
  justify-content: space-around;
  padding: 1.5625em 0;
  display: none;
  position: absolute;
  bottom: 0;
}

@media (min-width: 875px) {
  .VUWpEG_share {
    display: flex;
    position: relative;
  }
}

.VUWpEG_shareLink {
  z-index: 1;
}

.VUWpEG_shareOffline {
  pointer-events: none;
}

.VUWpEG_shareOffline .VUWpEG_shareLink {
  opacity: .3;
}

.VUWpEG_open {
  display: flex;
}

.VUWpEG_open:before {
  content: "";
  width: 100%;
  background: var(--main-color);
  position: fixed;
  top: 0;
  bottom: 0;
}

.VUWpEG_open:after {
  content: "";
  width: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
}

a.VUWpEG_openLink {
  color: currentColor;
}

.VUWpEG_openIcon {
  width: 3em;
  fill: currentColor;
  z-index: 1;
  display: block;
}

.VUWpEG_hidden {
  display: none;
}

._9VNcpq_loading {
  z-index: 20;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

._9VNcpq_spinner {
  fill: var(--main-color);
  filter: brightness(40%);
  height: 10vh;
  width: 10vw;
  animation: _9VNcpq_rotator 1.5s linear infinite;
  display: block;
}

._9VNcpq_path {
  stroke-dasharray: 200;
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke: var(--main-color);
  filter: brightness(40%);
  fill: none;
  stroke-width: 6px;
  stroke-linecap: round;
  animation: _9VNcpq_dash 1.5s ease-in-out infinite;
}

._9VNcpq_hidden {
  display: none;
}

@keyframes _9VNcpq_dash {
  0% {
    stroke-dashoffset: 200px;
  }

  50% {
    stroke-dashoffset: 50px;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 200px;
    transform: rotate(450deg);
  }
}

@keyframes _9VNcpq_rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

._K4Arq_container {
  box-sizing: border-box;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25em 0 25em;
  display: flex;
  position: absolute;
  inset: 0;
}

._K4Arq_container:before {
  opacity: .3;
}

@media print {
  ._K4Arq_container {
    z-index: 100;
    background: #fff;
    padding: 0;
    display: block;
  }

  ._K4Arq_container:before {
    opacity: 0;
  }
}

._K4Arq_info {
  width: 30%;
  height: 33.3125em;
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
  flex-direction: column;
  flex: 0 30%;
  display: flex;
}

@media print {
  ._K4Arq_info {
    page-break-before: always;
    padding: 0;
    display: block;
  }
}

._K4Arq_info ._K4Arq_header {
  height: 4.875em;
  box-shadow: var(--box-shadow-inset);
  box-sizing: border-box;
  background-position: 0 -55px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  align-items: center;
  padding: .9375em;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media print {
  ._K4Arq_info ._K4Arq_header {
    box-shadow: none;
    background: none;
  }
}

@media (min-width: 875px) {
  ._K4Arq_info ._K4Arq_header {
    box-shadow: none;
  }
}

._K4Arq_info ._K4Arq_title {
  color: var(--main-color);
  direction: ltr;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 900;
  position: relative;
  overflow: hidden;
}

html[dir="rtl"] ._K4Arq_info ._K4Arq_title {
  flex-direction: row-reverse;
  display: flex;
}

._K4Arq_empty {
  cursor: initial;
  background-color: #0000;
  border: 4px dotted #fff;
}

._K4Arq_content {
  height: 100%;
  max-height: 15.625em;
  box-sizing: border-box;
  max-height: none;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 .9375em;
  display: flex;
  position: relative;
  overflow: auto;
}

._K4Arq_contentItem {
  box-sizing: border-box;
  width: 100%;
  max-width: 31.25em;
  text-align: left;
  flex-shrink: 0;
  padding: .5em 0;
  display: flex;
  position: relative;
}

._K4Arq_contentItem:before {
  opacity: .3;
}

._K4Arq_contentItem:first-child {
  padding-top: .9375em;
}

._K4Arq_contentItem:last-child {
  padding-bottom: .9375em;
}

._K4Arq_icon {
  fill: currentColor;
  flex-shrink: 0;
  margin-right: .625em;
}

html[dir="rtl"] ._K4Arq_icon {
  margin-left: .625em;
  margin-right: 0;
}

._K4Arq_filter {
  min-height: 1.5625em;
  border-bottom: 1px solid var(--main-color);
  background-color: #fff;
  align-items: center;
  padding: .4375em .9375em;
  display: flex;
  position: relative;
}

._K4Arq_printButton {
  position: absolute;
  bottom: 1.25em;
  right: 1.25em;
}

@media print {
  ._K4Arq_printButton {
    display: none;
  }
}

/*# sourceMappingURL=index.105b1ed2.css.map */
