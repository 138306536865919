@import '../css/variables.css';

.root {
  width: 100%;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

html {
  width: 100%;
  height: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: var(--tertiary-background-color);
  line-height: 1.4;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 400;
}

a {
  color: var(--main-color);
  text-decoration: none;
}

p {
  margin: 0;
  padding: 0;
  color: var(--main-color);
  overflow: hidden;
  text-overflow: ellipsis;
}

article {
  width: 100%;
  max-width: 42rem;
  padding: 1.5rem;
  margin: 0 auto;
  background-color: var(--tertiary-background-color);
}

article ul,
article li {
  color: var(--main-color);
  margin: 0;
  padding: 0;
  list-style: none;
}

article li {
  display: flex;
  align-items: center;
}

article p {
  color: var(--main-color);
  padding: 0.625rem 0;
}

article img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  padding: 0.625rem 0;
  box-sizing: border-box;
}

article img[src*='icons'] {
  display: inline-block;
  margin: 0;
  margin-right: 0.625rem;
}

html[dir='rtl'] article img[src*='icons'] {
  margin-right: 0;
  margin-left: 0.625rem;
}

h2 {
  color: var(--main-color);
  margin: 1.875rem 0 1rem 0;
}

.hidden {
  display: none;
}

dl {
  font-size: 0.875rem;
}

dl > div {
  break-inside: avoid;
}

dl dd,
dl dt {
  display: inline-block;
  width: 50%;
  margin: 0 0 0.5rem 0;
  vertical-align: top;
}

dl dt {
  color: var(--gray-dark-2);
}

dl dt::after {
  content: ':';
}

.checkbox-list {
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 1.25rem;
}

.checkbox-list--item {
  width: 50%;
}

input[type='checkbox'],
input[type='radio'] {
  display: none;
}

input[type='checkbox'] + label,
input[type='radio'] + label {
  cursor: pointer;
  background-color: var(--default-background-color);
  fill: currentColor;
}

input[type='checkbox']:checked + label,
input[type='radio']:checked + label {
  fill: var(--secondary-color);
  background-color: currentColor;
}

.flyout {
  position: fixed;
  box-sizing: content-box;
  top: 50%;
  right: 0;
  width: 15rem;
  background-color: var(--secondary-background-color);
  padding: 1rem;
  border-bottom-left-radius: 0.25rem;
  transform: translateX(100%);
  transition: all 0.25s ease-in-out;
  box-shadow: var(--box-shadow);
}

.flyout header {
  font-weight: 700;
  margin-bottom: 1rem;
}

.flyout a {
  text-decoration: underline;
}

.flyout .cta {
  box-sizing: content-box;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  width: 3.43rem;
  height: 2.375rem;
  background-color: var(--secondary-background-color);
  padding: 1rem 0.5rem;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  cursor: pointer;
}

.flyout .cta svg {
  height: 100%;
}

.flyout-open {
  transform: translateX(0);
}
.icon.arrow {
  display: none;
  padding: 0.25rem;
}
.flyout-open .icon.arrow {
  display: block;
}

.icon.logo {
  display: block;
}
.flyout-open .icon.logo {
  display: none;
}

.debug {
  position: absolute;
  top: 5px;
  background: var(--error-color);
  padding: 3px 5px;
  border-radius: 3px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--secondary-color);
  z-index: 1000;
}
