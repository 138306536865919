:root {
  /* COLORS */
  --main-color: #000;
  --main-color-rgb: 0, 0, 0;
  --secondary-color: #fff;
  --highlight-color: #e6007d;

  --default-background-color: #fff;
  --primary-background-color: #002a5c;
  --secondary-background-color: #93c5ff;
  --tertiary-background-color: #e0eeff;
  --tertiary-background-color-rgb: 224, 238, 255;

  /* CHECKBOXES */
  --checkbox-color: #fff;
  --checkbox-higlight-color: #90b69f;

  /* TOGGLE */
  --toggle-color: #ccc;
  --toggle-enabled-color: #e6007d;

  /* BUTTON */
  --button-color: #ccc;
  --button-highlight-color: #e6007d;

  --mobile-breakpoint: 410px;
  --tablet-breaktpoint: 580px;
  --desktop-breakpoint: 875px;

  /* CATEGORIES */
  --food-drink-color: #84bd2f;
  --sleep-color: #258cc4;
  --hygiene-color: #087e87;
  --med-color: #d33e41;
  --consulting-color: #ef9c16;
  --misc-color: #7a5ca5;

  /* WIDTH */
  --desktop-list-width: 380px;

  /* SHADOWS */
  --button-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);

  --box-shadow: 0 2px 4px rgba(var(--main-color-rgb), 0.14),
    0 3px 4px rgba(var(--main-color-rgb), 0.12),
    0 1px 5px rgba(var(--main-color-rgb), 0.2);
  --box-shadow-inset: 0 -2px 1px rgba(0, 0, 0, 0.25);

  /* FLASH MESSAGES */
  --info-color: #ef9c16, 15;
  --success-color: #84bd2f;
  --error-color: #c80e11;

  /* ICONS */

  --icon-width: 1.5rem;
  --icon-height: 1.5rem;
}
